import React, { useEffect, useRef, useState } from "react"
import "./Accordion.scss"
import chevronUp                              from "../../../images/chevron-up.svg"
import chevronDown                            from "../../../images/chevron-down.svg"
import { useOnClickOutside }                  from "../../../utils/hooks"

export const Accordion = ({ category, products }) => {
  const [isOpen, setIsOpen] = useState(false)
  const productsListEl = useRef();
  const accordionEl = useRef();

  useOnClickOutside(accordionEl, () => setIsOpen(false));

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  /*useEffect(() => {
    if (isOpen) {
      const yOffset = -50;
      const y = accordionEl.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }, [isOpen])*/

  const goToProductPage = (product) => {
    const productUrl = 'https://my.assibro.it/prodotto/' + product.slug;
    window.open(productUrl, '_blank');
  }

  return !!category && !!products && (
    <div className={`accordion ${isOpen ? 'accordion--open' : ''}`}
         ref={accordionEl}>
      <div className="accordion__header" onClick={toggle}>
        <div className="accordion__header__left">
          <img src={category.icon_url} alt="" className="accordion__header__icon" />
          <div className="accordion__header__title">{category.name}</div>
        </div>
        <div className="accordion__header__chevron">
          <img src={isOpen ? chevronUp : chevronDown} alt="chevron" />
        </div>
      </div>
      <div ref={productsListEl}
           style={
             isOpen
               ? { height: productsListEl.current.scrollHeight + "px" }
               : { height: 0 }
           }
           className='accordion__content'>
        {products.map(p => (
          <div className="accordion__content__product" key={p.id}>
            <p className="accordion__content__product__title" onClick={() => goToProductPage(p)}>{p.name}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
