import React, { useEffect, useState } from "react"
import { useIntl }                    from "gatsby-plugin-intl"
import './Products.scss';
import axios         from "axios"
import { Accordion } from "../_ui/Accordion/Accordion"

export const Products = () => {
  const intl = useIntl();
  const [categories, setCategories] = useState([]);

  // TODO: use environment variables in the urls

  const fetchData = () => {
    axios
      .get('https://backend.assibro.it/api/landing/product_categories')
      .then(res => {
        const categories = res.data.product_categories;
        categories.forEach((category, i) => {
          category.sort = i+1;
        });
        // for each category fetch the products
        categories.forEach(category => {
          axios
            .get(`https://backend.assibro.it/api/landing/products/${category.id}`)
            .then(res => {
              setCategories(prevState => {
                return [...prevState, {
                  category,
                  products: res.data.products
                }].sort((a, b) => a.category.sort - b.category.sort);
              })
            })
        })
      })
  }

  useEffect(() => {
    fetchData();
  }, [])

  return !!categories && (
    <div className='products' id="products">
      <h1>{intl.formatMessage({ id: 'products.title' })}</h1>
      <p className='products__hint'>{intl.formatMessage({id: 'products.hint'})}</p>
      <div className="products__grid">
        {categories.map(c => (
          <Accordion category={c.category}
                     products={c.products}
                     key={c.category.id}
          />
        ))}
      </div>
    </div>
  )
}
