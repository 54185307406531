import React                 from 'react';
import { useIntl }           from 'gatsby-plugin-intl';
import './Homepage.scss';
import dashboard             from '../../images/dashboard.png';
import questionarioAssuntivo from '../../images/questionario-assuntivo.png';
import offerte               from '../../images/offerte.png';
import polizze               from '../../images/polizze.png';
import chat               from '../../images/chat.png';
import phones                from '../../images/doppi-telefoni.png';
import phonesMobile                   from '../../images/doppi-telefoni.png';
import HomepageBlock                  from './HomepageBlock/HomepageBlock';
import { neutralBgColor, warning200 } from '../../utils/colors';
import unipol                          from '../../images/insurance_companies/unipol-sai.png';
import aig                          from '../../images/insurance_companies/aig.png';
import uca                          from '../../images/insurance_companies/uca.png';
import europ           from '../../images/insurance_companies/europ.png';
import genialloyd      from '../../images/insurance_companies/genialloyd.png';
import adriatic        from '../../images/insurance_companies/adriatic.png';
import arag            from '../../images/insurance_companies/arag.jpg';
import metlife         from '../../images/insurance_companies/metlife.jpg';
import prima           from '../../images/insurance_companies/prima.png';
import cattolica       from '../../images/insurance_companies/cattolica-colori.png';
import realemutua      from '../../images/insurance_companies/reale-mutua.jpg';
import serenaFregonese from '../../images/serena-fregonese.png';
import alessandroMoro  from '../../images/alessandro-moro.png';
import { navigate }    from 'gatsby-link';
import { Products }    from "../Products/Products"
import scrollTo        from "gatsby-plugin-smoothscroll"

const Homepage = () => {
  const intl = useIntl();
  return (
    <div className="homepage">
      <HomepageBlock customId={'homepage-block-1'}
                     mode={'cta'}
                     title={intl.formatMessage({ id: 'homepage.main-hero' })}
                     description={[intl.formatMessage({ id: 'homepage.main-hero-subtitle' })]}
                     cta={{
                       text: intl.formatMessage({ id: 'homepage.main-hero-cta' }),
                       action: () => scrollTo('#products'),
                     }}
                     images={[polizze]}
                     hasBg={false}/>
      <Products />
      <HomepageBlock customId={'homepage-block-2'}
                     mode={'default'}
                     images={[questionarioAssuntivo]}
                     headline={intl.formatMessage({ id: 'homepage.second-hero-headline' })}
                     title={intl.formatMessage({ id: 'homepage.second-hero-title' })}
                     description={[intl.formatMessage({ id: 'homepage.second-hero-subtitle' })]}
                     bulletList={[intl.formatMessage({ id: 'homepage.second-hero-li-1' }),
                       intl.formatMessage({ id: 'homepage.second-hero-li-2' }),
                       intl.formatMessage({ id: 'homepage.second-hero-li-3' })]}
                     hasBg={false}/>
      <HomepageBlock customId={'homepage-block-3'}
                     mode={'reverse'}
                     images={[offerte]}
                     headline={intl.formatMessage({ id: 'homepage.third-hero-headline' })}
                     title={intl.formatMessage({ id: 'homepage.third-hero-title' })}
                     description={[intl.formatMessage({ id: 'homepage.third-hero-subtitle' })]}
                     bulletList={[intl.formatMessage({ id: 'homepage.third-hero-li-1' }),
                       intl.formatMessage({ id: 'homepage.third-hero-li-2' }),
                       intl.formatMessage({ id: 'homepage.third-hero-li-3' })]}
                     hasBg={neutralBgColor}/>
      <HomepageBlock customId={'homepage-block-4'}
                     mode={'default'}
                     images={[dashboard]}
                     headline={intl.formatMessage({ id: 'homepage.fourth-hero-headline' })}
                     title={intl.formatMessage({ id: 'homepage.fourth-hero-title' })}
                     description={[intl.formatMessage({ id: 'homepage.fourth-hero-subtitle' })]}
                     bulletList={[intl.formatMessage({ id: 'homepage.fourth-hero-li-1' }),
                       intl.formatMessage({ id: 'homepage.fourth-hero-li-2' }),
                       intl.formatMessage({ id: 'homepage.fourth-hero-li-3' })]}
                     hasBg={false}/>
      <HomepageBlock customId={'homepage-block-5'}
                     mode={'reverse'}
                     images={[chat]}
                     headline={intl.formatMessage({ id: 'homepage.fourth-bis-hero-headline' })}
                     title={intl.formatMessage({ id: 'homepage.fourth-bis-hero-title' })}
                     description={[intl.formatMessage({ id: 'homepage.fourth-bis-hero-subtitle' })]}
                     bulletList={[intl.formatMessage({ id: 'homepage.fourth-bis-hero-li-1' }),
                       intl.formatMessage({ id: 'homepage.fourth-bis-hero-li-2' }),
                       intl.formatMessage({ id: 'homepage.fourth-bis-hero-li-3' })]}
                     hasBg={neutralBgColor}/>
      <HomepageBlock customId={'homepage-block-6'}
                     mode={'big'}
                     images={[phones, phonesMobile]}
                     headline={intl.formatMessage({ id: 'homepage.fifth-hero-headline' })}
                     title={intl.formatMessage({ id: 'homepage.fifth-hero-title' })}
                     descriptions={[
                       {
                         title: intl.formatMessage({ id: 'homepage.big-block-descr-1-title' }),
                         text: intl.formatMessage({ id: 'homepage.big-block-descr-1-text' }),
                       },
                       {
                         title: intl.formatMessage({ id: 'homepage.big-block-descr-2-title' }),
                         text: intl.formatMessage({ id: 'homepage.big-block-descr-2-text' }),
                       },
                       {
                         title: intl.formatMessage({ id: 'homepage.big-block-descr-3-title' }),
                         text: intl.formatMessage({ id: 'homepage.big-block-descr-3-text' }),
                       },
                       {
                         title: intl.formatMessage({ id: 'homepage.big-block-descr-4-title' }),
                         text: intl.formatMessage({ id: 'homepage.big-block-descr-4-text' }),
                       },
                       {
                         title: intl.formatMessage({ id: 'homepage.big-block-descr-5-title' }),
                         text: intl.formatMessage({ id: 'homepage.big-block-descr-5-text' }),
                       },
                     ]}
                     hasBg={neutralBgColor}/>
      <HomepageBlock mode={'cta-handshakes'}
                      title={intl.formatMessage({ id: 'homepage.sixth-hero-title' })}
                      description={[intl.formatMessage({ id: 'homepage.sixth-hero-subtitle' })]}
                      bulletList={[{
                        title: intl.formatMessage({ id: 'homepage.sixth-hero-li-1' }),
                        text: intl.formatMessage({ id: 'homepage.sixth-hero-li-1-text' }),
                      }, {
                        title: intl.formatMessage({ id: 'homepage.sixth-hero-li-2' }),
                        text: intl.formatMessage({ id: 'homepage.sixth-hero-li-2-text' }),
                      }, {
                        title: intl.formatMessage({ id: 'homepage.sixth-hero-li-3' }),
                        text: intl.formatMessage({ id: 'homepage.sixth-hero-li-3-text' }),
                      }]}
                      cta={{
                        text: intl.formatMessage({ id: 'homepage.sixth-hero-cta' }),
                        action: () => navigate('/contattaci'),
                      }}
                      hasBg={warning200}/>
      <HomepageBlock customId={'homepage-block-7'}
                     mode={'partners'}
                     title={intl.formatMessage({ id: 'homepage.seventh-hero-title' })}
                     description={[intl.formatMessage({ id: 'homepage.seventh-hero-subtitle' })]}
                     images={[unipol, cattolica, realemutua, uca, arag, aig, metlife, genialloyd, prima, europ, adriatic]}
                     hasBg={false}/>
      <HomepageBlock customId={'homepage-block-8'}
                     mode={'quotes'}
                     title={intl.formatMessage({ id: 'homepage.eight-hero-title' })}
                     description={[intl.formatMessage({ id: 'homepage.eight-hero-subtitle' })]}
                     quotes={[
                       {
                         text: 'Processo di registrazione rapido e veloce. \n Il mio consulente ha capito subito le mie esigenze e mi ha fatto risparmiare oltre 100€',
                         author: {
                           name: 'Serena Fregonese',
                           role: 'Commessa',
                           profileImage: serenaFregonese,
                         },
                       },
                       {
                         text: 'Finalmente tutte le mie polizze in un’unica app!\n Ora posso gestire le mie polizze in totale libertà e risparmiare tempo!',
                         author: {
                           name: 'Alessandro Moro',
                           role: 'Imprenditore',
                           profileImage: alessandroMoro,
                         },
                       },
                     ]}
                     hasBg={false}/>
    </div>
  );
};
export default Homepage;
