import React       from "react"
import Helmet      from "react-helmet"
import Homepage    from "../components/Homepage/Homepage"
import Layout      from "../components/Layout/Layout"
import { graphql } from "gatsby"


export default ({ data }) => {
  return (
    <Layout categories={data.prismic.allMacrocategorys.edges.map(c => c.node)}
            documents={data.prismic.allDocumentss.edges[0].node}>
      <>
        <Helmet title={"Clienti"} titleTemplate={`Assibro | %s`} meta={[
          {
            name: `description`,
            content: "Assibro",
          },
          {
            property: `og:title`,
            content: "Assibro",
          },
          {
            property: `og:description`,
            content: "Assibro",
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: "Assibro",
          },
          {
            name: `twitter:title`,
            content: "Assibro",
          },
          {
            name: `twitter:description`,
            content: "Assibro",
          },
        ]} />
        <Homepage />
      </>
    </Layout>
  )
}

export const query = graphql`
    {
        prismic {
            allMacrocategorys(sortBy: position_ASC) {
                edges {
                    node {
                        name
                        icon
                        _meta {
                            uid
                            id
                        }
                    }
                }
            }
            allDocumentss {
                edges {
                    node {
                        attachment_3_4 {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        general_conditions {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        privacy_policy {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        electronic_signature {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`
